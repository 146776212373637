
import {
	getRequest,
	postRequest
} from "@/http/http.js"

// 系统用户表格
export const adminListInfo = query => postRequest('/admin/adminListInfo', query);

// 系统用户删除
export const adminInfoDel = query => postRequest('/admin/adminInfoDel', query);

// 权限管理表格
export const sysRoleInfoList = query => postRequest('/admin/sysRoleInfoList', query);

// 权限管理表格新增编辑
export const adminInfoAdd = query => postRequest('/admin/adminInfoAdd', query);

// 权限管理表格新增编辑
export const sysRoleInfoAdd = query => postRequest('/admin/sysRoleInfoAdd', query);

// 权限管理表格删除
export const sysRoleInfoDel = query => postRequest('/admin/sysRoleInfoDel', query);

// 授权
export const sysMenuListInfo = query => postRequest('/admin/sysMenuListInfo', query);

// 授权保存
export const sysRolePermissionAdd = query => postRequest('/admin/sysRolePermissionAdd', query);

// 下拉框
export const sysRoleOptionList = query => postRequest('/admin/sysRoleOptionList', query);

// 停用
export const adminStartStop = query => postRequest('/admin/adminStartStop', query);

// 修改密码
export const adminPasswordEdit = query => postRequest('/admin/adminPasswordEdit', query);



