<template>
	<div class="bigBox">
		<h2>系统用户</h2>
		<div class="">
			<div class="iptsBox margin-top20">
				<el-form ref="form" :model="query" label-width="80px">
					<div class="flex-sb">
						<div class="row-me">
							<el-input v-model="query.mobile" prefix-icon="Search" placeholder="用户账号"
								class="accountPut"></el-input>
							<el-input v-model="query.name" prefix-icon="Search" placeholder="用户姓名"
								class="namePut"></el-input>
							<el-button type="primary" icon="Search" class="searchs" @click="searchs">
								搜索</el-button>
							<el-button class="agains" @click="resetting">重置
							</el-button>
						</div>
						<div class="row-me row-center addDiv" v-if="this.powerListName.includes('新增按钮')"
							@click="addOrEdit">
							<img src="../../assets/img/icon_xzyh.png" class="iconImg" />&nbsp;
							<span class="youzhi">新增用户</span>
						</div>
					</div>
				</el-form>
			</div>
			<div class="tabsBox  margin-top20">
				<el-table ref="singleTable" :data="ststemArr" border header-cell-class-name="table-header-style"
					cell-class-name="table-cell-style">
					<el-table-column type="index" label="序号" width="70" align="center">
					</el-table-column>
					<el-table-column prop="mobile" label="用户账号" align="center">
					</el-table-column>
					<el-table-column prop="name" label="用户姓名" align="center">
					</el-table-column>
					<el-table-column prop="mobile" label="用户手机号" align="center">
					</el-table-column>
					<el-table-column prop="email" label="邮箱" align="center">
					</el-table-column>
					<el-table-column prop="role_name" label="权限" align="center">
					</el-table-column>
					<el-table-column prop="stateText" label="状态" align="center">
						<template #default="scope">
							<span style="color: #EB4B4D;" v-if="scope.row.state == 0">禁用</span>
							<span style="color: #33C77C;" v-else>开启</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" align="left" width="290">
						<template #default="scope">
							<el-button type="text" size="small" v-if="this.powerListName.includes('编辑按钮')"
								@click="addOrEdit(scope.row)">编辑</el-button>
							<el-button type="text" size="small" @click="deactivate(scope.row)">
								<!-- 停用 -->
								<div v-if="this.powerListName.includes('其他按钮')">
									<div class="" v-if="scope.row.stateText == '正常'">停用</div>
									<div class="" v-else>启用</div>
								</div>
							</el-button>
							<el-button type="text" size="small" v-if="this.powerListName.includes('其他按钮')"
								@click="editPassword(scope.row)">修改密码</el-button>
							<el-button type="text" size="small" v-if="this.powerListName.includes('删除按钮')"
								@click="deleteClick(scope.row)">删除</el-button>
							<!-- <el-popconfirm title="确定删除吗?" @confirm="deleteClick(scope.row)"><template #reference>
									<el-button type="text" size="small">删除</el-button>
								</template>
							</el-popconfirm> -->
						</template>
					</el-table-column>
				</el-table>
				<div class="row-me row-center flex-end1 margin-top10" v-if="ststemArr.length>0">
					<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
						:current-page.sync="pageNo" :total="total">
					</el-pagination>
				</div>
			</div>

		</div>
		<!-- 新增用户 -->
		<el-dialog v-model="dialogUser" :title="userTit" width="30%">
			<el-form :model="form" label-position="right">
				<div class="leftDiv">
					<el-form-item label="手机号">
						<el-input onkeyup="if(this.value.length==1){this.value=this.value.replace(
							/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
							onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
							maxlength="11" v-model="form.mobile" placeholder="请输入" style="width: 400px;" />
					</el-form-item>
				</div>
				<el-form-item label="账号密码">
					<el-input v-model="form.password" placeholder="请输入" show-password style="width: 400px;" />
				</el-form-item>
				<el-form-item label="确认密码">
					<el-input v-model="form.password1" placeholder="请输入" show-password style="width: 400px;" />
				</el-form-item>
				<el-form-item label="选择权限">
					<el-select v-model="form.role_id" placeholder="请选择" style="width: 400px;">
						<el-option v-for="(item,index) in selectRoleRrr" :key="index" :label="item.name"
							:value="item.id" />
					</el-select>
				</el-form-item>
				<el-form-item label="用户姓名">
					<el-input v-model="form.name" placeholder="请输入" style="width: 400px;" />
				</el-form-item>
				<div class="leftDiv1">
					<el-form-item label="邮箱">
						<el-input v-model="form.email" placeholder="请输入" style="width: 400px;" />
					</el-form-item>
				</div>
				<div class="leftDiv1">
					<el-form-item label="状态">
						<el-switch v-model="form.state" :active-text="activeText"
							@click.native="changeIsNeedCaptcha(form.state)">
						</el-switch>
					</el-form-item>
				</div>

			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogUser = false" class="width100height40">取消</el-button>
					<el-button type="primary" @click="userOk">
						确定
					</el-button>
				</span>
			</template>
		</el-dialog>
		<el-dialog v-model="dialogPass" title="修改密码" width="30%">
			<el-form :model="forms" label-position="right">
				<div class="leftDiv2">
					<el-form-item label="原密码">
						<el-input v-model="forms.password1" placeholder="请输入" show-password style="width: 400px;" />
					</el-form-item>
				</div>
				<div class="leftDiv2">
					<el-form-item label="新密码">
						<el-input v-model="forms.password2" placeholder="请输入" show-password style="width: 400px;" />
					</el-form-item>
				</div>

				<el-form-item label="确认密码">
					<el-input v-model="forms.password3" placeholder="请输入" show-password style="width: 400px;" />
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogPass = false" style="width: 100px;height: 40px;">取消</el-button>
					<el-button type="primary" @click="passOk">
						确定
					</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		adminListInfo,
		adminInfoDel,
		adminInfoAdd,
		sysRoleOptionList,
		adminStartStop,
		adminPasswordEdit
	} from "@/api/systemManagement/systemManagement.js"
	import {
		ElMessage
	} from 'element-plus'
	export default {
		data() {
			return {
				dialogUser: false,
				dialogPass: false,
				value1: false,
				form: {
					name: "",
					mobile: "",
				},
				query: {
					name: "",
					mobile: "",
				},
				forms: {
					password1: "",
					password2: "",
					password3: "",
				},
				ststemArr: [],
				selectRoleRrr: [],
				pageNo: 1,
				total: 0,
				activeText: '启用',
				powerListName: [], //权限按钮
			}
		},
		mounted() {
			var list = JSON.parse(sessionStorage.getItem('menuArrayRegulate'));
			console.log(list, 'listlist');
			var nameList = [];

			for (var i = 0; i < list.length; i++) {
				if (list[i].name == "系统管理") {
					for (var j = 0; j < list[i].children.length; j++) {

						if (list[i].children[j].name == "系统用户") {
							nameList = list[i].children[j].children;
						}
					}
				}
			}

			if (nameList != undefined) {
				for (var i = 0; i < nameList.length; i++) {
					this.powerListName.push(nameList[i].name)
				}
			}
			this.getStstemList();
			this.selectRole();
		},
		methods: {
			//页数改变
			handleCurrentChange(pageNo) {
				this.pageNo = pageNo
				this.getStstemList()
			},
			// 表格
			async getStstemList() {
				const res = await adminListInfo({
					mobile: this.query.mobile,
					name: this.query.name,
					page: this.pageNo,
				})
				this.ststemArr = res.data.listInfo
				this.total = res.data.allListNumber
			},
			// 搜索
			searchs() {
				this.getStstemList()
			},
			// 重置
			resetting() {
				this.query.mobile = "";
				this.query.name = "";
				this.pageNo = 1;
				this.getStstemList()
			},
			// // 删除
			async handleClickDel(row) {
				const res = await adminInfoDel({
					id: row.id
				})
				ElMessage({
					message: '删除成功',
					type: 'success',
				})
				this.getStstemList()
			},
			addOrEdit(row) {
				console.log(row, 'rowrow')
				if (row.id) {
					this.userTit = '编辑用户'
					this.id = row.id
					this.form = JSON.parse(JSON.stringify(row))
					this.form.state = row.state ? true : false
				} else {
					this.userTit = '新增用户'
					this.form = {}
				}
				if (this.form.state == true) {
					this.activeText = '启用';
				} else {
					this.activeText = '禁用';
				}



				this.dialogUser = true
			},
			changeIsNeedCaptcha(type) {
				if (type == true) {
					this.activeText = '启用';
				} else {
					this.activeText = '禁用';
				}
			},
			// 下拉框
			async selectRole() {
				const res = await sysRoleOptionList()
				this.selectRoleRrr = res.data
			},
			// 新增编辑确定按钮
			async userOk() {
				if (this.form.mobile == '' || this.form.mobile == undefined) {
					this.$message.error("手机号不能为空");
					return false;
				}
				if (!(/^1[3456789]\d{9}$/.test(this.form.mobile))) {
					this.$message.error("手机号有误，请重新输入！")
					return false;
				}
				if (this.form.password == '' || this.form.password == undefined) {
					this.$message.error("账号密码不能为空");
					return false;
				}
				if (this.form.password1 == '' || this.form.password1 == undefined) {
					this.$message.error("确认密码不能为空");
					return false;
				}
				if (this.form.password != this.form.password1) {
					this.$message.error("两次密码输入不一致，请从新输入");
					return false;
				}
				if (this.form.role_id == '' && this.form.role_id == undefined) {
					this.$message.error("请选择权限");
					return false;
				}

				if (this.form.name == '' && this.form.name == undefined) {
					this.$message.error("用户姓名不能为空");
					return false;
				}
				if (this.form.email != '' && this.form.email != undefined) {
					var reg1 = new RegExp("^\\w+@\\w{2,6}(\\.\\w{2,3})+$", "i");
					if (reg1.test(this.form.email)) {

					} else {
						this.$message.error("请输入正确的邮箱地址！")
						return false
					}
				}
				if (this.form.state == true) {
					this.form.state = 1
				} else {
					this.form.state = 2
				}
				if (this.id == '') {
					const res = adminInfoAdd({
						...this.form
					}).then((res) => {
						if (res) {
							this.$message.success("提交成功");
							this.dialogUser = false
							this.form = ''
							this.getStstemList()
						}
					}).catch((error) => {
						console.log(error)
					})
				} else {
					const res1 = adminInfoAdd({
						id: this.id,
						...this.form
					}).then((res) => {
						if (res) {
							this.$message.success("编辑成功");
							this.dialogUser = false
							this.form = ''
							this.getStstemList()
						}
					}).catch((error) => {
						console.log(error)
					})
				}

			},
			// 停用
			async deactivate(row) {
				var text;
				var text1;
				if (row.stateText == '正常') {
					text = '确定停用吗？'
					text1 = '已停用'
				} else {
					text = '确定启用吗？'
					text1 = '已启用'
				}
				this.$confirm(text, "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.$post('/admin/adminStartStop', {
						id: row.id
					}).then((res) => {
						if (res) {
							this.$message.success(text1)
							this.getStstemList()
						}
					}).catch((error) => {
						console.log(error)
					})
				}).catch(() => {});

				// const res = await adminStartStop({
				// 	id: row.id
				// })
				// this.getStstemList()
			},
			deleteClick(row) {
				console.log(row, 'rowrow')
				this.$confirm("确定删除吗？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.$post('/admin/adminInfoDel', {
						id: row.id
					}).then((res) => {
						if (res) {
							this.$message.success('删除成功')
							this.getStstemList()
						}
					}).catch((error) => {
						console.log(error)
					})
				}).catch(() => {});
			},
			// 修改密码
			editPassword(row) {
				this.forms.password1 = '';
				this.forms.password2 = '';
				this.forms.password3 = '';
				this.id = row.id
				this.dialogPass = true
			},
			async passOk() {
				if (this.forms.password1 == '') {
					this.$message.error("原密码不能为空");
					return false;
				}
				if (this.forms.password2 == '') {
					this.$message.error("新密码不能为空");
					return false;
				}
				if (this.forms.password3 == '') {
					this.$message.error("确认密码不能为空");
					return false;
				}
				if (this.forms.password2 != this.forms.password3) {
					this.$message.error("两次密码输入不一致，请从新输入");
					return false;
				}
				const res = adminPasswordEdit({
					id: this.id,
					password: this.forms.password3,
					old_password: this.forms.password1
				}).then((res) => {
					if (res) {
						ElMessage({
							message: '修改成功',
							type: 'success',
						})
						this.dialogPass = false
					}
				}).catch((error) => {
					console.log(error)
				})

			}
		}

	}
</script>

<style lang="scss" scoped="scoped">
	.bigBox {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;
	}

	.agains {
		padding: 0 31px !important;
		margin-left: 10px;
		height: 40px;
		font-size: 16px;
	}

	.leftDiv2 {
		margin-left: 10px;
	}

	.leftDiv {
		margin-left: 17px;
	}

	.leftDiv1 {
		margin-left: 30px;
	}

	.searchs {
		margin-left: 10px;
		font-size: 16px;
	}

	.accountPut {
		margin-right: 20px;

		/deep/.el-input__wrapper {
			width: 360px;
			height: 40px;
		}
	}

	.namePut {
		/deep/.el-input__wrapper {
			width: 200px;
			height: 40px;
		}
	}

	.addDiv {
		border: 1px solid #D4D7E0;
		border-radius: 4px;
		padding: 7px 10px;
		cursor: pointer;

		.iconImg {
			width: 26px;
			height: 26px;
		}

		.youzhi {
			font-size: 18px;
		}
	}


	.electIpt {
		width: 200px;
		height: 40px;
		margin-left: 10px;
	}

	.fenye {
		float: right;
		margin-top: 40px;

	}

	::v-deep .el-form-item__label {
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 40px;
		color: #252630;
	}

	::v-deep .el-button--primary {
		width: 100px;
		height: 40px;
	}
</style>